<template>
  <IseiVerification
    tenant-alias="aifintech"
    financial-model-alias="europeanpaysystem"
  >
    <template v-slot:header-image>
      <b-img center src="/images/eps.svg" style="width: 50%;" />
    </template>
    <template v-slot:header-text>
      ТОВ “Европейська платіжна система”
    </template>
    <template v-slot:system-link>
      <b-card-text>
        Для укладання договору на
        <a href="https://paypong.ua/about/legalinf" target="_blank"
          >отримання платіжних послуг</a
        >, просимо пройти електронну ідентифікацію за допомогою підсистеми
        верифікації ІСЕІ
      </b-card-text>
    </template>
  </IseiVerification>
</template>

<script>
import IseiVerification from "@/containers/IseiVerification.vue";
export default {
  name: "Eps",
  components: {
    IseiVerification
  }
};
</script>
